import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import attr from '../../vue.config'
import md5 from 'js-md5'
import { MessageBox } from 'element-ui'
import { Message } from 'element-ui'
import * as signalr from '@microsoft/signalr'
import jwtdecode from 'jwt-decode'
import router from '../router'



Vue.use(VueAxios, axios)


function GetToken () {
  var token=window.sessionStorage.getItem('ca_login_token')
  if(token){
    return token
  }
  else{
    router.push('/')
  }
}

function InitWs () {
  var user= JesToken()
  const conn = new signalr.HubConnectionBuilder()
    .withUrl(attr.devServer.proxy['/api'].target + '/chathub?userid=' + user.user_login_id +'&user_type='+user.user_type+'&spid='+user.user_sp_id)
    .withAutomaticReconnect()
    .build()
  return conn
}

function JesToken(){
  return jwtdecode(window.sessionStorage.getItem('ca_login_token'))
}


function CheckUserAuth(id){
  var item=JSON.parse(window.sessionStorage.getItem('user_auth')) 
  if(item!==null&&item!==undefined){
    if(item.length===0){
      return true
    }
    var arr=item.filter(a=>a===id)
    if(arr.length===0){
      Message({
        message: '当前操作员无操作权限',
        type: 'error',
        duration: 3000
      })
      return false
    }
    else
      return true
  }
  else
    return true
}





async function Send(method,parkId){
  var title=''
  switch(method){
    case 'dev':title='设备';break;
    case 'park':title='车场';break;
    case 'post':title='岗亭';break;
    case 'gate':title='通道';break;
    case 'fee':title='费率';break;
    case 'card':title='卡类';break;
    case 'car':title='车辆';break;
    case 'user':title='操作员';break;
    case 'blacklist':title='黑名单';break;
  }
  var msg = Message({
    message: '正在下发'+title+'信息',
    type: 'warning'
  })
  if(!parkId)
    parkId=0
  return new Promise((resolve, reject)=>{
    Get("sendtoclient/send?name="+method+'&parkId='+parkId).then(res=>{
      msg.close()
      if(res.rpStatus===10000){
        Message({
          message: '下发'+title+'信息成功',
          type: 'success'
        })
      }
      else{
        Message({
          message: res.rpMsg,
          type: 'error',
          duration: 3000
        })
      }
      resolve(res)
    })
  })
}



// axios  post
async function Post (Url, param, checkSign) {
  const Token = GetToken()
  axios.defaults.headers.common.token = Token

  if (checkSign === undefined) {
    // formdata形式上传时，不做sign校验
    if (param !== null && param !== undefined) {
      var p = md5(JSON.stringify(param))
      axios.defaults.headers.common.sign = p
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: attr.devServer.proxy['/api'].target + '/' + Url,
      data: param,
      timeout: 60000,
      traditional: true,
      Headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      
      if (res.data.rpStatus === 10002) {
        MessageBox.alert(' 用户登录已过期', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            window.location.href = '/'
            window.sessionStorage.removeItem('ca_login_token')// token
            window.localStorage.removeItem('ca_login_info')// 用户登录信息
          }
        })
      } else {
        resolve(res.data)
      }
    }).catch(function (error) {
      resolve(error)
    })
  })
}

async function Get (Url, param) {
  const Token = GetToken()
  axios.defaults.headers.common.token = Token

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: attr.devServer.proxy['/api'].target + '/' + Url,
      params: param,
      timeout: 60000,
      traditional: true,
      Headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      resolve(res.data)
    }).catch(function (error) {
      if (error.response.status === 401) {
        MessageBox.alert(' 用户登录已过期', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            window.location.href = '/'
            window.sessionStorage.removeItem('ca_login_token')// token
            window.localStorage.removeItem('ca_login_info')// 用户登录信息
          }
        })
      }
      else {
        resolve(res.data)
      }
    })
  })
}


//补全
function toDecimal2(num){
  var f = parseFloat(num);
  if (isNaN(f)) {
      return '';
  }
  var f = Math.round(num*100)/100;
  var s = f.toString();
  var rs = s.indexOf('.');
  if (rs < 0) {
      rs = s.length;
      s += '.';
  }
  while (s.length <= rs + 2) {
      s += '0';
  }
  return s;
}

//图片地址
function GetImageUrl(name){
  let url= attr.devServer.proxy['/api'].target
  url= url.substr(0,url.length-3)
  url= url+'picture/'+name
  console.log(url)
  return url
}




function ErrorMsg (code) {
  var msg = ''
  switch (code) {
    case 10001: msg = '账号或密码错误'; break
    case 10002: msg = 'Token为空'; break
    case 10003: msg = 'Token错误'; break
    case 10004: msg = '账号未登录'; break
    case 10005: msg = '登录账号过期'; break
    case 10007: msg = '操作失败'; break
    case 10008: msg = '未检测到提交的数据项'; break
  }
  return msg
}

// 输出格式化时间
function FormateDate (time, typ) {
  if(time===undefined||time===null)
    return ''
  else{
    var date = new Date(time)
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    var t1 = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    var t2 = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
    var t3= (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    if (typ === 0) { return t1 }
    if (typ === 1) { return t2 }
    if (typ === 2) { return t1 + ' ' + t2 }
    if(typ===3){return t3}
  }
}

// 将数据转换为树形数据
function FommatTreeData (arrlist, pid, id) {
  const listOjb = {} // 用来储存{key: obj}格式的对象
  const treeList = []
  // 将数据变换成{key: obj}格式，方便下面处理数据

  for (let i = 0; i < arrlist.length; i++) {
    listOjb[arrlist[i][id]] = arrlist[i]
  }
  // 根据pid来将数据进行格式化
  for (let j = 0; j < arrlist.length; j++) {
    // 判断父级是否存在
    const haveParent = listOjb[arrlist[j][pid]]
    if (haveParent) {
      // 如果有没有父级children字段，就创建一个children字段
      !haveParent.children && (haveParent.children = [])
      // 在父级里插入子项
      haveParent.children.push(arrlist[j])
    } else {
      // 如果没有父级直接插入到最外层
      treeList.push(arrlist[j])
    }
  }
  return treeList
}

export default {
  InitWs,
  GetToken,
  JesToken,
  CheckUserAuth,
  Send,
  Post,
  Get,
  ErrorMsg,
  FormateDate,
  FommatTreeData,
  toDecimal2,
  GetImageUrl
}
