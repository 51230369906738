import Vue from 'vue'
import Vuex from 'vuex'
import util from '../util/util'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 全局参数
    meunItem: [],

    MediaTypeDataList:[],


    CardDataList:[],
    BlackDataList:[],
    CarDataForm:{
      CarDataList:[],
      default_short_plateno:'',
      total:0
    },
    DevDataList:[],
    ParkDataList:[],
    PostDataList:[],

    ErrProneDataList:[],

  },



  mutations: {
    // set
    SetMeunItem: function (state, data) {
      state.meunItem = data
    },

    MediaTypeDataListMutations(state,data){
      state.MediaTypeDataList=data
    },



    CardDataListMutations(state,data){
      state.CardDataList=data
    },

    BlackListDataListMutations(state,data){
      state.BlackDataList=data
    },

    CarDataListMutations(state,data){
      state.CarDataForm.CarDataList=data.list
      state.CarDataForm.total=data.total
      state.CarDataForm.default_short_plateno=data.short_plateno
    },

    DevDataListMutations(state,data){
      state.DevDataList=data
    },

    ParkDataListMutations(state,data){
      state.ParkDataList=data
    },

    PostDataListMutations(state,data){
      state.PostDataList=data
    },

    ErrProneDataListMutations(state,data){
      state.ErrProneDataList=data
    }
  },


  getters: {
    // get
    //对数据进行过滤
    GetMeunItem: state => state.meunItem,

    filterCardDataList:state=>(cardId,includeTemp)=>{
      if(cardId===null){
        if(includeTemp===null||includeTemp===true){
          return state.CardDataList
        }
        else{
          return state.CardDataList.filter(a=>a.card_id>0&&a.card_limit!==null&&a.card_limit!==9)
        }
      }
      else{
        return state.CardDataList.filter(item=>item.card_id===cardId)//获取指定的卡类
      }
    },

    //将车场数据转换为树结构
    changeParkDataToTreeData:state=>{
      var data = JSON.parse(JSON.stringify(state.ParkDataList))
      return util.FommatTreeData(data, 'pk_sub_id', 'pk_id')
    },

  },



  actions: {
    //媒体类型
    getMediaTypeDataList(store){
      util.Get('mediatype/getmediatypedatalist').then(res=>{
        store.commit('MediaTypeDataListMutations',res.list)
      })
    },



    //卡类
    getCardDataList(store){
      util.Get('card/getcarddatalist').then(res=>{
        store.commit('CardDataListMutations',res.list)
      })
    },

    //黑名单
    getBlackListDataList(store){
      util.Get('blacklist/getblacklist').then(res => {
        store.commit('BlackListDataListMutations',res.list)
      })
    },

    //车辆
    getCarDataList(store,payload){
      util.Get('car/getcardatalist',payload).then(res => {
        store.commit('CarDataListMutations',{list:res.list[0],short_plateno:res.list[1],total:res.total})
      })
    },

    //设备
    getDevDataList(store){
      util.Get('dev/getdevdatalist').then(res => {
        store.commit('DevDataListMutations',res.list)
      })
    },

    //车场
    getParkDataList(store){
      util.Get('park/getparkdatalist').then(res => {
        store.commit('ParkDataListMutations',res.list)
      })
    },

    //岗亭
    getPostDataList(store){
      util.Get('post/getpostdatalist').then(res => {
        store.commit('PostDataListMutations',res.list)
      })
    },

    //易错车牌
    getErrProneDataList(store,payload){
      util.Get('errprone/getdatalist?plateno='+payload).then(res=>{
        store.commit('ErrProneDataListMutations',res.list)
      })
    }
  },
  modules: {
    // 为全局变量分组
  }
})
